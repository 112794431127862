<template>
  <div>
    <h1 class="display-1">Исследования</h1>
    <v-divider class="mt-2 mb-8"></v-divider>
    <h1 class="display-2 mt-12 text-center">Раздел в разработке</h1>
    <div class="underconstruct"></div>
  </div>
</template>

<script>
export default {
  name: 'Exploration',
  data() {
    return {
    }
  },
  methods: {
  },
  computed: {
  },
  created () {
  }
}
</script>

<style scoped lang="scss">
  .underconstruct {
    height: 512px;
  }
</style>
